import { useState, useEffect } from "react";
import {
  TextField, Button, Typography, AppBar, Toolbar, Divider, FormControlLabel, LinearProgress,
  FormControl, RadioGroup, Radio
} from "@mui/material";
import axios, { AxiosError } from "axios"
import { DropZone } from "../component/util";
import { useParams } from "react-router";
import { Stack } from "@mui/material";
import { settings } from "../../settings";
import { Property } from "../../types";
import { CulpticonApi } from "../../utils/api";


const api = new CulpticonApi()

const AdminEdit = () => {
  const [museum_name, setMuseumName] = useState("");
  const [name, setName] = useState("");
  const [property, setProperty] = useState<Property>("その他");
  const [commentary, setCommentary] = useState("");
  const [excavator, setExcavator] = useState("");
  const [location, setLocation] = useState("");
  const [imageFileText, setImageFileText] = useState("")
  const [objFileText, setObjFileText] = useState("")
  const [localImageUrl, setLocalImageUrl] = useState("")
  const [localObjFileUrl, setLocalObjFileUrl] = useState("")
  const [localObjNontextureFileUrl, setLocalObjNontextureFileUrl] = useState("")
  const [objFileNontextureText, setObjFileNontextureText] = useState("")
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState<File | null>(null)
  const [objFile, setObjFile] = useState<File | null>(null)
  const [objNontextureFile, setObjNontextureFile] = useState<File | null>(null)
  let mapSrc = "https://maps.google.com/maps?output=embed&q=" + location
  const { id } = useParams();

  useEffect(() => {
    api.readBuildingId(id as string).then((res: any) => {
      setMuseumName(res.museum_name);
      setName(res.name);
      setProperty(res.property_name);
      setCommentary(res.commentary);
      setExcavator(res.excavator);
      setLocation(res.location);
      setImageFileText(res.image_path)
      setObjFileText(res.archaeological_data_path)
      setObjFileNontextureText(res.archaeological_no_texture_data_path)
    }).catch((err) => {
      console.log(err);
    });
  }, []);

  const handleUpload = async () => {
    setLoading(true);
    try {
      const updatedData = {
        museum_name,
        name,
        property,
        commentary,
        excavator,
        location,
      };

      const response = await api.updateArchaeologicalData(id as string, updatedData);

      const imageUploadPromise = imageFile ? api.updateImageFile(id as string, imageFile) : Promise.resolve(null);
      const objFileUploadPromise = objFile ? api.updateObjFile(id as string, objFile) : Promise.resolve(null);
      const objNontextureFileUploadPromise = objNontextureFile ? api.updateObjNontextureFile(id as string, objNontextureFile) : Promise.resolve(null);


      await Promise.all([imageUploadPromise, objFileUploadPromise, objNontextureFileUploadPromise]);

      if (response.status === 200) {
        alert("更新に成功しました");
        window.location.href = "/delete";
      } else {
        alert("更新に失敗しました");
      }
    } catch (error) {
      console.error(error);
      alert("更新中にエラーが発生しました");
    } finally {
      setLoading(false);
    }
  };


  return (
    <>
      <div style={{ zIndex: 19, position: "fixed", width: "100%" }}>
        <AppBar
          sx={{
            backgroundColor: "#fff",
            paddingTop: "18px",
            height: "64px",
          }}
        >
          <Toolbar
            sx={{
              margin: "0 auto",
              padding: 0,
              paddingLeft: "5px",
              paddingRight: "5px",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
            variant="dense"
            disableGutters
          >
            <Typography sx={{ color: "#2b2b2b", fontSize: 25, fontWeight: "bold", marginLeft: 1 }}>管理画面</Typography>
          </Toolbar>
        </AppBar>
      </div>
      <div style={{ height: 100 }} />

      <div style={{ width: 600, marginLeft: "auto", marginRight: "auto" }}>
        <Typography sx={{ fontSize: 22, fontWeight: "bold", color: "#2b2b2b" }}>考古資料の修正</Typography>
        <Button onClick={() => window.location.href = "/delete"} sx={{ marginTop: -8, marginLeft: 56 }} variant="outlined" color="primary">登録の確認と削除</Button>
        <Divider sx={{ width: "100%", marginBottom: 1, marginTop: -1.5 }} />
        <Typography sx={{ color: "#5f5f5f", marginTop: 1 }}>所蔵館/組織名</Typography>
        <div><TextField sx={{ width: "100%" }} value={museum_name} onChange={setMuseumName && ((e) => setMuseumName(e.target.value))} id="outlined-basic" size="small" /></div>
        <Typography sx={{ color: "#5f5f5f", marginTop: 1 }}>名前</Typography>
        <div><TextField sx={{ width: "100%" }} value={name} onChange={setName && ((e) => setName(e.target.value))} id="outlined-basic" size="small" /></div>
        <Typography sx={{ color: "#5f5f5f", marginTop: 1, marginBottom: -1 }}>属性</Typography>
        <FormControl>
          <RadioGroup
            value={property}
            onChange={(e) => {
              const property = Property.parse(e.target.value);
              setProperty(property);
            }}
            row
          >
            <FormControlLabel value="土器" control={<Radio />} label="土器" />
            <FormControlLabel value="石器" control={<Radio />} label="石器" />
            <FormControlLabel value="骨角器" control={<Radio />} label="骨角器" />
            <FormControlLabel value="その他" control={<Radio />} label="その他" />
          </RadioGroup>
        </FormControl>
        <Typography sx={{ color: "#5f5f5f" }}>解説</Typography>
        <TextField
          sx={{ width: "100%", marginBottom: 1 }}
          id="outlined-multiline-static"
          value={commentary}
          multiline
          rows={6}
          onChange={setCommentary && ((e) => setCommentary(e.target.value))}
        />
        <Typography sx={{ color: "#5f5f5f" }}>所蔵者</Typography>
        <div><TextField sx={{ width: "100%", marginBottom: 1 }} value={excavator} onChange={setExcavator && ((e) => setExcavator(e.target.value))} id="outlined-basic" size="small" /></div>
        <Typography sx={{ color: "#5f5f5f" }}>関連地図</Typography>
        <div><TextField sx={{ width: "100%", marginBottom: 1 }} value={location} onChange={setLocation && ((e) => setLocation(e.target.value))} id="outlined-basic" size="small" /></div>
        <iframe title="googleMaps" src={mapSrc} width="100%" height="300" frameBorder="0" />
        <Stack spacing={2} marginTop={2} marginBottom={2}>
          <DropZone
            text="画像ファイルをドラッグ&amp;ドロップ"
            subText={imageFile ? imageFile.name : imageFileText.slice(26)}
            options={{
              onDrop: (files: File[]) => {
                const file = files[0];
                if (file) {
                  setImageFile(file);
                  setLocalImageUrl(URL.createObjectURL(file)); // ローカルファイルへのURLを生成
                }
              },
              accept: {
                'image/*': []
              }
            }}
          />
          {imageFile ? (
            <a
              href={localImageUrl}
              download={imageFile.name}
            >
              {imageFile.name}をダウンロード
            </a>
          ) : (
            <a
              href={settings.apiUrl + imageFileText.slice(1)}
              download
              target="_blank"
              rel="noopener noreferrer"
            >
              画像ファイルをダウンロード
            </a>
          )}
          <DropZone
            text="GLBファイルをドラッグ&amp;ドロップ"
            subText={objFile !== null ? objFile?.name : objFileText.slice(29)}
            options={{
              onDrop: (files: File[]) => {
                const file = files[0];
                if (file) {
                  setObjFile(file);
                  // ローカルファイルへのURLを生成
                  setLocalObjFileUrl(URL.createObjectURL(file));
                }
              },
              accept: {
                '.glb': []
              }
            }}
          />
          {objFile ? (
            <a href={localObjFileUrl} download={objFile?.name}>
              {objFile?.name}をダウンロード
            </a>
          ) : (
            <a href={settings.apiUrl + objFileText.slice(1)} download target="_blank" rel="noopener noreferrer">
              GLBファイルをダウンロード
            </a>
          )}
          <DropZone
            text="GLBファイル(テクスチャ無し)をドラッグ&amp;ドロップ"
            subText={objNontextureFile !== null ? objNontextureFile?.name : objFileNontextureText.slice(40)}
            options={{
              onDrop: (files: File[]) => {
                const file = files[0];
                if (file) {
                  setObjNontextureFile(file);
                  // ローカルファイルへのURLを生成
                  setLocalObjNontextureFileUrl(URL.createObjectURL(file));
                }
              },
              accept: {
                '.glb': []
              }
            }}
          />
          {objNontextureFile ? (
            <a href={localObjNontextureFileUrl} download={objNontextureFile?.name}>
              {objNontextureFile?.name}をダウンロード
            </a>
          ) : (
            <a href={settings.apiUrl + objFileNontextureText.slice(1)} download target="_blank" rel="noopener noreferrer">
              非テクスチャGLBファイルをダウンロード
            </a>
          )}
        </Stack>
        {loading === false ? <Button onClick={handleUpload} style={{ marginTop: 20, marginBottom: 40 }} variant="contained" size="large">更新</Button> :
          <LinearProgress style={{ marginTop: 20, marginBottom: 40 }} />}

      </div>
    </>
  );
};

export default AdminEdit;
