import React from "react";
import { Card, CardActionArea, Typography, Box, Stack } from '@mui/material';
import { To, Link } from "react-router-dom";

export const ItemCard = ({ img, name, text, to }: { img: string, name: string, text: string, to: To }) => {
    return (
        <Card sx={{ height: "100%" }}>
            <CardActionArea component={Link} to={to} sx={{ height: "100%" }}>
                <Stack
                    justifyContent="end"
                    height="100%"
                    minHeight={150}
                    sx={{
                        backgroundImage: `url(${img})`, backgroundRepeat: "no-repeat",
                        backgroundSize: "cover", backgroundPosition: "center"
                    }}
                >
                    <Box component="div" paddingLeft={2} paddingRight={2} paddingBottom={1}>
                        <Typography color="white" fontWeight="bold" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                            {name}
                        </Typography>
                        <Typography color="white" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                            {text}
                        </Typography>
                    </Box>
                </Stack>
            </CardActionArea>
        </Card>
    );
};
