import Header from "../util/header";
import React from "react";
import { useParams } from "react-router";
//import { Qr } from "../util/qr"

const Expansion: React.FC = () => {
  const [state, setState] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { id } = useParams<"pid" | "id">();
  const handleChange = () => {
    if (state === false) {
      setState(true);
      console.log(state)
    } else {
      setState(false);
      console.log(state)
    }
  };

  const style = {
    height: window.innerHeight,
    width: "100%",
    overflow: "scroll",
  };

  return (
    <>
      <Header page_name={"ホーム"} />
      {/*
      <div style={{ position: "relative" }}>
        <div style={style}>
          <div style={{ height: 30 }} />

          <Data id={id as string} />
          {/*<div style={{ textAlign: "center", marginTop: -46, marginLeft: 240 }}>
            <Button onClick={handleOpen} variant="outlined" color="inherit" size="small" endIcon={<QrCode2Icon sx={{ fontSize: 34 }} />}>QRコード</Button>
            </div>
          <Divider
            style={{
              marginTop: -14,
              height: 2,
              width: 350,
              marginLeft: "auto",
              marginRight: "auto",
              color: "#696969",
            }}
          ></Divider>

          <div style={{ textAlign: "center", marginTop: 12 }}>
            <>
              <Card style={{ width: 350, height: 300, marginLeft: "auto", marginRight: "auto", backgroundColor: "#000" }}>
                <Suspense fallback={<div style={{ color: "white", textAlign: "center", marginTop: 125 }}>Now Loading...</div>}>
                  <ThreeJs glbSrc={id as string} texture={state} />
                </Suspense>
              </Card>
              <IconButton sx={{ marginRight: -9, marginTop: -3 }} onClick={handleOpen} aria-label="upload picture" component="span">
                <OpenWithIcon sx={{ fontSize: 34 }} />
              </IconButton>
              <TwitterShareButton url={window.location.href} title='' style={{ marginLeft: 75, marginTop: 13 }}>
                <TwitterIcon size={34} round />
              </TwitterShareButton>
              <FacebookShareButton url={window.location.href} quote='' style={{ margin: 5 }}>
                <FacebookIcon size={34} round />
              </FacebookShareButton>
              <FormControlLabel
                style={{ marginLeft: 50, marginTop: -23 }}
                control={
                  <Switch checked={state} onChange={() => handleChange()} name="jason" />
                }
                label="テクスチャ無し"
              />
            </>
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Card style={{ marginTop: 100, width: 355, height: 600, marginLeft: "auto", marginRight: "auto", backgroundColor: "#000" }}>
              <IconButton sx={{ marginRight: -7, marginTop: 0.5, marginLeft: 38 }} onClick={handleClose} aria-label="upload picture" component="span">
                <ClearIcon sx={{ fontSize: 34, color: "#fff" }} />
              </IconButton>
              <DialogContent>
                {/*<Qr />
              </DialogContent>
                {/*<ThreeJsExpand glbSrc={id as string} texture={state} />
            </Card>

          </Modal>
          <div style={{ width: 350, marginLeft: "auto", marginRight: "auto", }}>
            <Typography
              style={{
                fontSize: 21,
                marginRight: "auto",
                marginTop: 15,
                marginBottom: 15,
                color: "#000",
                fontWeight: "bold",
              }}
            >
              解説
              </Typography>

            <Divider
              style={{
                marginTop: -13,
                height: 2,
                width: 350,
                marginLeft: "auto",
                marginRight: "auto",
                color: "#696969",
              }}
            ></Divider>
          </div>
          <div style={{ width: 350, marginLeft: "auto", marginRight: "auto", marginTop: 10, }}>
            <Typography
              display="inline"
              style={{
                fontSize: 18,
                marginTop: 20,
                marginBottom: 0,
                color: "#000",
              }}
            >
              縄文土器は一般的に縄目文様のついた土器のことをいいます。 「縄文土器」の名前の由来は、大森貝塚を発掘したアメリカの動物学者、エドワード·S·モースが、報告書で「cord marked pottery（縄目をつけられた土器）」と記したことに始まり、日本語訳されたことで縄文土器といわれるようになります。
              </Typography>
          </div>
          <div style={{ width: 350, marginLeft: "auto", marginRight: "auto", }}>

            <Typography
              style={{
                fontSize: 21,
                marginRight: "auto",
                marginTop: 15,
                marginBottom: 15,
                color: "#000",
                fontWeight: "bold",
              }}
            >
              発掘場所：天神山遺跡
              </Typography>

            <Divider
              style={{
                marginTop: -13,
                height: 2,
                width: 350,
                marginLeft: "auto",
                marginRight: "auto",
                color: "#696969",
              }}
            ></Divider>
          </div>
          <div style={{ width: 350, marginLeft: "auto", marginRight: "auto", marginTop: 13, }}>

            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3279.2455036188862!2d136.9564270285581!3d34.72420562688019!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6004f2c9e1812ce1%3A0x1c3f67e4954dd7a1!2z44CSNDcwLTM1MDEg5oSb55-l55yM55-l5aSa6YOh5Y2X55-l5aSa55S65aSn5LqV5aGp5bGL!5e0!3m2!1sja!2sjp!4v1653060941994!5m2!1sja!2sjp" width="350" height="300" frameBorder="0" ></iframe>
          </div>

          <div style={{ height: 200 }} />

        </div>
      </div>
      <BottomNavigationFixedLink value={1} />
      */}
    </>
  );
};

export default Expansion;
