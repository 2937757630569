import { DataSearch } from "../component/search/search";
import { InsideBuilding } from "../component/museum";
import { Home } from "../component/home/home";
import { BottomNavigation, Header } from "../component";
import { settings } from "../../settings";
import React from "react";
import { useParams } from "react-router";
import HomeIcon from '@mui/icons-material/Home';
import MuseumIcon from '@mui/icons-material/Museum';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import MediaQuery from "react-responsive";

const Main: React.FC = () => {
  const headerName: { [key: string]: string } = { 0: "資料検索", 1: 'トップ画面', 2: '博物館一覧' }
  const { id } = useParams<"id">();
  const [value, setValue] = React.useState(Number(id));

  return (
    <>
      <div style={{ zIndex: 19, position: "fixed", width: "100%" }}>
        <div style={{ zIndex: 2, position: "relative" }}><Header page_name={headerName[value]} /></div>
        <div style={{ width: "100%", maxWidth: settings.maxWidth, marginLeft: "auto", marginRight: "auto" }}>
          <MediaQuery query="(max-width: 800px)">
            <div></div>
          </MediaQuery>
          <MediaQuery query="(min-width: 801px)">
            <div style={{ textAlign: "right", position: "sticky", marginTop: -44, marginRight: 40, zIndex: 10 }}>
              <Button onClick={() => setValue(0)} sx={{ color: value === 0 ? "#52a9ff" : "#939393", marginRight: 3 }} startIcon={<SearchIcon />}>
                資料検索
              </Button>
              <Button onClick={() => setValue(1)} sx={{ color: value === 1 ? "#52a9ff" : "#939393", marginRight: 3 }} startIcon={<HomeIcon />}>
                ホーム
              </Button>
              <Button onClick={() => setValue(2)} sx={{ color: value === 2 ? "#52a9ff" : "#939393", marginRight: 3 }} startIcon={<MuseumIcon />}>
                博物館一覧
              </Button>
            </div>
          </MediaQuery>
        </div>
      </div>
      <div style={{ height: 50 }} />
      {value === 0 ? (<DataSearch />) : value === 1 ? (<Home />) : <InsideBuilding />}
      <MediaQuery query="(max-width: 800px)">
        <BottomNavigation value={value} onChange={setValue} />
      </MediaQuery>
      <MediaQuery query="(min-width: 801px)">
        <div>
        </div>
      </MediaQuery>
    </>
  );
};
export default Main;
