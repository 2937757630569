import React from "react";
import { Link, Typography } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

export const HeaderNavigation: React.FC<{
  text?: string;
  href?: string;
  back_navigation?: boolean;
  img_src?: string;
  onClick?: () => void;
}> = ({
  text = "",
  img_src = `${process.env.PUBLIC_URL}/images/back_button.png`,
  href,
  back_navigation = true,
  onClick,
}) => {
  let back_navi: JSX.Element | null = null;
  if (back_navigation) {
    back_navi = (
      <ArrowBackIosNewIcon style={{ marginRight: 4, color: "#3C4043",}}/>
    );
  }
  if (href !== undefined || onClick !== undefined) {
    return (
      <Link
        underline="none"
        href={href}
        sx={{ display: "flex", alignItems: "center" }}
        onClick={onClick}
      >
        {back_navi}
        <Typography
          display="inline"
          style={{
            color: "#3C4043",
            fontWeight: "bold",
            fontSize: "22px",
          }}
          variant="h6"
        >
          {text}
        </Typography>
      </Link>
    );
  }
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {back_navi}
      <Typography
        display="inline"
        sx={{
          color: "#fff",
          fontWeight: "bold",
          fontSize: "22px",
        }}
        variant="h6"
      >
        {text}
      </Typography>
    </div>
  );
};
