import { useEffect, ReactNode, useMemo, useState } from "react";
import { Typography, Alert, Stack, Link, Collapse } from "@mui/material";
import { Link as RouterLink, To } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { CulpticonApi } from "../../../utils/api";
import { Qr, ItemCard } from '../util';
import { getRandomElements } from "../../../utils/getRandomElements";
import { Body, Center } from '../layout';
import { settings } from "../../../settings";

const api = new CulpticonApi();

const Title = ({ children }: { children: ReactNode }) => {
  return (
    <Typography
      marginBottom={0}
      marginTop={1}
      fontSize={19}
      fontWeight="bold"
      style={{ color: "#1C1D1F" }}
    >
      {children}
    </Typography>
  );
};

const CenteredTextLink = ({ to, children }: { to: To, children: ReactNode }) => {
  return (
    <Center containerStyle={{ display: "flex", alignItems: "center", height: "100%" }}>
      <RouterLink style={{ textDecoration: "none" }} to={to}>
        <Typography>{children}</Typography>
      </RouterLink>
    </Center>
  );
};

const ItemListSwiper = ({ items, to }: { items: any[], to: To }) => {
  return (
    <Swiper
      slidesPerView="auto"
      spaceBetween={10}
    >
      {items.map((item: any, index: number) => {
        const path = settings.apiUrl + item.image_path.slice(1);
        return (
          <SwiperSlide key={index} style={{ width: 200, height: 150 }}>
            <ItemCard img={path} name={item.name} text={item.commentary} to={`/contents/${item.id}`} />
          </SwiperSlide>
        );
      })
      }
      <SwiperSlide style={{ width: 200, height: 150 }}>
        <CenteredTextLink to={to}>
          すべて表示
        </CenteredTextLink>
      </SwiperSlide>
    </Swiper>
  );
};

export const Home = () => {
  const [open, setOpen] = useState(true);
  const [jsonlist, setJsonlist] = useState<any>([]);
  const recomendElements = useMemo(() => getRandomElements<any>(jsonlist, 5), [jsonlist]);

  useEffect(() => {
    api.readBuildings().then((res: any) => {
      setJsonlist(res.data);
    });
  }, []);

  return (
    <Body paddingTop={3}>
      <Collapse in={open}>
        <Alert
          severity="info"
          sx={{ marginBottom: 3 }}
          onClose={() => setOpen(false)}
        >
          <Link underline="none" onClick={()=>window.open('https://docs.google.com/forms/d/e/1FAIpQLSdr2KB126AsYkRUkkv44TQMpeKkbq9ZetaMYRDD4rR-W4V0IQ/viewform')}>アンケート</Link>にご協力お願いします
        </Alert>
      </Collapse>
      <Qr />
      <Stack spacing={2} marginTop={2}>
        <div>
          <Title>今日のオススメ</Title>
          <Swiper
            modules={[Pagination]}
            slidesPerView={1}
            speed={300}
            autoplay
            pagination
          >
            {recomendElements.map((item: any, index: number) => {
              const path = settings.apiUrl + item.image_path.slice(1);
              return (
                <SwiperSlide key={index} style={{ width: "100%", minHeight: 170, maxHeight: 400, aspectRatio: "5 / 3" }}>
                  <ItemCard img={path} name={item.name} text={item.commentary} to={`/contents/${item.id}`} />
                </SwiperSlide>
              );
            })
            }
          </Swiper>
        </div>
        <div>
          <Title>考古資料(土器)</Title>
          <ItemListSwiper items={jsonlist.filter((item: any) => item.property_name === "土器")} to="/doki" />
        </div>
        <div>
          <Title>考古資料(石器)</Title>
          <ItemListSwiper items={jsonlist.filter((item: any) => item.property_name === "石器")} to="/sekki" />
        </div>
        <div>
          <Title>考古資料(骨格器/骨格標本)</Title>
          <ItemListSwiper items={jsonlist.filter((item: any) => item.property_name === "骨角器")} to="/kokkakuki" />
        </div>
      </Stack>
    </Body>
  );
};
