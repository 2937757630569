import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import LaunchIcon from '@mui/icons-material/Launch';
import ArticleIcon from '@mui/icons-material/Article';
import Link from "@mui/material/Link";

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export const ChangeLanguage = () => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };

  const list = () => (

    <div>

    
      <List>
        <Link underline="none" href={"/credit"}>
          <ListItem button>
            <ListItemIcon>
              {<ArticleIcon />}
            </ListItemIcon>
            <ListItemText sx={{ marginLeft: -3, color: "#3C4043", }} primary={"利用規約"} />
          </ListItem>
        </Link>
      </List>
      <Divider />
      <List>
        <Link underline="none" href={"/outline"}>
          <ListItem button>
            <ListItemIcon>
              {<ArticleIcon />}
            </ListItemIcon>
            <ListItemText sx={{ marginLeft: -3, color: "#3C4043", }} primary={"アプリ概要"} />
          </ListItem>
        </Link>
      </List>
      <Divider />
      <List>
        <Link underline="none" onClick={()=>window.open('https://docs.google.com/forms/d/e/1FAIpQLSdr2KB126AsYkRUkkv44TQMpeKkbq9ZetaMYRDD4rR-W4V0IQ/viewform')}>
          <ListItem button>
            <ListItemIcon>
              {<QuestionAnswerIcon />}
            </ListItemIcon>
            <ListItemText sx={{ marginLeft: -3, color: "#3C4043", }} primary={"利用者アンケート"} />
          </ListItem>
        </Link>
      </List>

      <Divider />
      <List>
        <Link underline="none" onClick={()=>window.open('http://www.num.nagoya-u.ac.jp/')} >
          <ListItem button>
            <ListItemIcon>
              {<LaunchIcon />}
            </ListItemIcon>
            <ListItemText sx={{ marginLeft: -3, color: "#3C4043", }} primary={"名大博物館公式HP"} />
          </ListItem>
        </Link>
      </List>
      <Divider />


    </div>

  );

  return (
    <div>
      {(['right'] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton onClick={toggleDrawer(anchor, true)} aria-label="upload picture" component="span" sx={{ color: "#939393", marginTop: -1 }}>
            <MenuIcon style={{ fontSize: "30px" }} />
          </IconButton>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list()}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
