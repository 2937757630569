import { z } from "zod";

export const Settings = z.object({
    maxWidth: z.number(), origin: z.string().url(), apiUrl: z.string().url()
});

export type Settings = z.infer<typeof Settings>;

export const Property = z.enum(["土器", "石器", "骨角器", "その他"]);

export type Property = z.infer<typeof Property>;
