import React from "react";
import "./App.css";

import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import 'swiper/css';
import "swiper/css/pagination";

const App = () => {
  return (
    <div>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
