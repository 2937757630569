import React from "react";
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { useTheme } from "@mui/material";

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '50px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: "pointer"
} as const;

export const DropZone = ({ options, text = "ファイルをドラッグ&amp;ドロップ", subText }: {
    options?: DropzoneOptions, text?: string, subText?: string
}) => {
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone(options);
    const theme = useTheme();

    const style = React.useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? { borderColor: theme.palette.primary.main } : {}),
        ...(isDragAccept ? { borderColor: theme.palette.success.light } : {}),
        ...(isDragReject ? { borderColor: theme.palette.error.main } : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    return (
        <div className="container">
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>{text}</p>
                {subText === undefined ? null : <p style={{ fontWeight: "bold" }}>{subText}</p>}
            </div>
        </div>
    );
};
