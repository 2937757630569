import React, { useEffect } from "react";
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import ClearIcon from '@mui/icons-material/Clear';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { QRCodeCanvas } from "qrcode.react";

export const Qr = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const qrcode = (
    <QRCodeCanvas
      id="qrCode"
      value={"https://culpticon.jp"}
      size={355}
      level={"H"}
    />
  );


  return (
    <div>
      <Button size="small" color="inherit" onClick={handleOpen} variant="outlined" startIcon={<QrCode2Icon />}>
        QRコード共有
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        <div style={{ marginTop: 120, width: 355, height: 420, marginLeft: "auto", marginRight: "auto"}}>
          <IconButton sx={{ marginRight: -20, marginTop: 0.5, marginLeft: 39 }} onClick={handleClose} aria-label="upload picture" component="span">
            <ClearIcon sx={{ fontSize: 34, color: "#fff" }} />
          </IconButton>
          <div style={{marginTop:0}}>
            {qrcode} 
          </div>
        </div>
        
            
      </Modal>
    </div>
  )
}
