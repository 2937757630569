import { CSSProperties } from "react";
import Grid, { Grid2Props } from '@mui/material/Unstable_Grid2';

interface CenterProps extends Grid2Props {
    containerStyle?: CSSProperties
}

export const Center = ({ children, containerStyle, ...props }: CenterProps) => {
    return (
        <Grid container justifyContent="center" style={containerStyle}>
            <Grid boxSizing="border-box" {...props}>
                {children}
            </Grid>
        </Grid>
    );
};
