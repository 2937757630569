import React from "react";
import { createBrowserRouter, ScrollRestoration, Outlet, Navigate } from "react-router-dom";
import { Main, Contents, Credit, Usage, Outline, Kokkakuki, Doki, Sekki, Genre, Museum, Keyword, Expansion, Admin, Delete, SearchEngine, AdminEdit } from "./views";

const RootElement = () => {
  return <><Outlet /><ScrollRestoration /></>;
};

export const router = createBrowserRouter([
  {
    element: <RootElement />,
    children: [
      {
        path: "/",
        element: <Navigate to="/main/1" />
      },
      {
        path: "/contents/:id",
        element: <Contents />
      },
      {
        path: "/expansion/:id",
        element: <Expansion />
      },
      {
        path: "/usage/:id",
        element: <Usage />
      },
      {
        path: "/kokkakuki",
        element: <Kokkakuki />
      },
      {
        path: "/doki",
        element: <Doki />
      },
      {
        path: "/sekki",
        element: <Sekki />
      },
      {
        path: "/outline",
        element: <Outline />
      },
      {
        path: "/main/:id",
        element: <Main />
      },
      {
        path: "/genre",
        element: <Genre />
      },
      {
        path: "/museum",
        element: <Museum />
      },
      {
        path: "/searchengine",
        element: <SearchEngine />
      },
      {
        path: "/searchengine",
        element: <SearchEngine />
      },
      {
        path: "/keyword",
        element: <Keyword />
      },
      {
        path: "/admin",
        element: <Admin />
      },
      {
        path: "/adminedit/:id",
        element: <AdminEdit />
      }, 
      {
        path: "/credit",
        element: <Credit />
      },
      {
        path: "/delete",
        element: <Delete />
      },
    ]
  }
]);
