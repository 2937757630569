import { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import axios, { AxiosError } from "axios"
import { Link } from 'react-router-dom';
import { CulpticonApi } from "../../utils/api";
import { Typography, AppBar, Toolbar, Divider, Card, CardMedia, CardActionArea, LinearProgress } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { settings } from "../../settings";
import EditIcon from '@mui/icons-material/Edit';

const api = new CulpticonApi()

const Delete = () => {
  const [loading, setLoading] = useState(false);
  const [loadingId, setLoadingId] = useState("");
  const [jsonlist, setJsonlist] = useState<any>([]);

  useEffect(() => {
    api.readBuildings().then((res: any) => {
      setJsonlist(res.data)
      return res.data
    }).catch((err) => {
      console.log(err);
    });
  }, []);

  const handleDelete = (id: string) => {
    const res = window.confirm("削除してもよろしいですか？");
    if (res) {
      setLoadingId(id)
      setLoading(true)
      axios.get(settings.apiUrl + "/archaeological_data_delete_id/" + id)
        .then((res) => {
          console.log(res.data)
          alert("削除に成功しました");
          window.location.href = "/delete";
          setLoading(false)
        })
        .catch((e: AxiosError) => {
          console.error(e)
          alert("削除に失敗しました");
          setLoading(false)
        })
    }
    else {
      // キャンセルならアラートボックスを表示
      alert("キャンセルしました");
    }
  }

  return (
    <>
      <div style={{ zIndex: 19, position: "fixed", width: "100%" }}>
        <AppBar
          sx={{
            backgroundColor: "#fff",
            paddingTop: "18px",
            height: "64px",
          }}
        >
          <Toolbar
            sx={{
              margin: "0 auto",
              padding: 0,
              paddingLeft: "5px",
              paddingRight: "5px",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
            variant="dense"
            disableGutters
          >
            <Typography sx={{ color: "#2b2b2b", fontSize: 25, fontWeight: "bold", marginLeft: 1 }}>管理画面</Typography>

          </Toolbar>
        </AppBar>
      </div>
      <div style={{ height: 100 }} />

      <div style={{ width: 600, marginLeft: "auto", marginRight: "auto" }}>
        <Typography sx={{ fontSize: 22, fontWeight: "bold", color: "#2b2b2b" }}>登録の確認と削除</Typography>
        <Button onClick={() => window.location.href = "/admin"} sx={{ marginTop: -8, marginLeft: 56 }} variant="outlined" color="primary">考古資料の登録</Button>
        <Divider sx={{ width: "100%", marginBottom: 1, marginTop: -1.5 }} />
        {jsonlist.map((todo: any, id: number) => {
          const path = settings.apiUrl + todo.image_path.slice(1)
          return (
            <div key={id} style={{ marginTop: 10 }}>
              <Card sx={{ maxWidth: 180 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={path}
                  />
                  <div style={{ marginLeft: 10, textAlign: "left" }} />
                </CardActionArea>
              </Card>
              <div style={{ marginTop: -139, marginLeft: 190 }}>
                <Typography>所蔵館/組織名：{todo.museum_name}</Typography>
                <Typography>名前：{todo.name.length > 11 ? (todo.name).slice(0, 11) + "…" : todo.name}</Typography>
                <Typography>属性：{todo.property_name.length > 11 ? (todo.property_name).slice(0, 11) + "…" : todo.property_name}</Typography>
                <Typography>解説：{todo.commentary.length > 11 ? (todo.commentary).slice(0, 11) + "…" : todo.commentary}</Typography>
                <Typography>所蔵者：{todo.excavator.length > 11 ? (todo.excavator).slice(0, 11) + "…" : todo.excavator}</Typography>
                <Typography>関連地図：{todo.location.length > 11 ? (todo.location).slice(0, 11) + "…" : todo.location}</Typography>
                {loading === true && loadingId === todo.id ? <LinearProgress sx={{ marginLeft: 0, marginTop: -0.5 }} /> :
                  <Button sx={{ marginLeft: 37, marginTop: -20 }} onClick={() => handleDelete(todo.id)} variant="outlined" color="error" startIcon={<DeleteIcon />}>
                    削除
                  </Button>
                }
                {loading === true && loadingId === todo.id ? <LinearProgress sx={{ marginLeft: 0, marginTop: -0.5 }} /> :
                  <Button sx={{ marginLeft: 37, marginTop: -13 }} component={Link} to={"/adminedit/" + todo.id} variant="outlined" color="primary" startIcon={<EditIcon />}>
                    編集
                  </Button>
                }
              </div>
              <Divider sx={{ width: "100%", marginTop: loading === true ? 0.5 : -4 }} />
            </div>
          );
        })}

      </div>
    </>
  );
};
export default Delete;
