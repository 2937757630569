import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import Card from '@mui/material/Card';
import { CulpticonApi } from "../../utils/api";
import { Body } from "./layout";

const api = new CulpticonApi()

type Props = {
  building_name: string;
  imagelink_name: string;
  id: number;
  map?: boolean;
};

const BuildingLink: React.FC<Props> = ({
  building_name,
  imagelink_name,
  id,
  map = false,
}) => {
  let map_elem: JSX.Element | null = null;
  return (
    <Link underline="none" href={"/usage/" + building_name}>
      <Card
        style={{
          maxWidth: 700, 
          width: "100%", 
          marginTop: -5,
        }}
      >
        <ListItem button sx={{ display: "flex" }}>
          <div style={{ marginLeft: -10, marginTop: 2 }}>
          </div>
          <Typography
            gutterBottom
            variant="h5"
            component="h3"
            style={{
              fontSize: 19,
              marginTop: 3,
              marginBottom: 9,
              marginLeft: 10,
              color: "#3C4043",
            }}
          >
            {building_name}
          </Typography>
          {map_elem}
        </ListItem>
        <Divider
          style={{
            width: "auto",
            marginLeft: 0,
            marginRight: 0,
            color: "#696969",
            marginTop: -8,
          }}
        ></Divider>
      </Card>
    </Link>
  );
};
export const InsideBuilding = () => {
  const [jsonlist, setJsonlist] = React.useState<any>([]);
  let museum_name_list: any = []

  useEffect(() => {
    api.readBuildings().then((res: any) => {
      setJsonlist(res.data)
      console.log(res.data)
      return res.data
    }).catch((err) => {
      console.log(err);
    });
  }, []);

  return (
    <Body>
      {jsonlist.map((todo: any, id: number) => {
        let sum = 0
        if (museum_name_list.includes(todo.museum_name)) {
          sum += 1
          console.log(todo.name)
          console.log(id)
        }

        museum_name_list.push(todo.museum_name)
        return (
          <div style={{ marginTop: sum === 0 ? 11 : 0 }}>
            {sum === 0 ?
              <BuildingLink
                building_name={todo.museum_name}
                imagelink_name={"/images/hakubutukann.jpeg"}
                id={id}
              /> : <div />}
          </div>
        )
      })}
    </Body>
  );
};
