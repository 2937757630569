import Header from "../util/header";
import React, { useEffect } from "react";
import { Typography, Grid } from "@mui/material";
import Divider from "@mui/material/Divider";
import { CulpticonApi } from "../../../utils/api";
import { Body } from "../layout";
import { ItemCard } from "../util";
import { settings } from "../../../settings";

const api = new CulpticonApi()

const KoukoKokkakuki = () => {
  const [jsonlist, setJsonlist] = React.useState<any>([]);

  useEffect(() => {
    api.readBuildings().then((res: any) => {
      setJsonlist(res.data)
      return res.data
    }).catch((err) => {
      console.log(err);
    });
  }, []);

  return (
    <>
      <Header page_name="ホーム" />
      <Body>
        <Typography variant="h5" fontWeight="bold" marginBottom={1}>
          考古資料(骨角器)
        </Typography>
        <Divider />
        <Grid container spacing={1} marginTop={2}>
          {jsonlist.filter((item: any) => item.property_name === "骨角器").map((item: any, index: number) => {
            const path = settings.apiUrl + item.image_path.slice(1)
            return (
              <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                <ItemCard img={path} name={item.name} text={item.commentary} to={`/contents/${item.id}`} />
              </Grid>
            );
          })}
        </Grid>
      </Body>
    </>
  );
};

export default KoukoKokkakuki;
