import React, { useEffect } from "react";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { Typography } from "@mui/material";
import { CardActionArea } from '@mui/material';
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import { CulpticonApi } from "../../../utils/api";
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { Body } from "../layout";
import { settings } from "../../../settings";

const api = new CulpticonApi()

export const DataSearch: React.FC = () => {
  const [jsonlist, setJsonlist] = React.useState<any>([]);
  const [id, setId] = React.useState("");
  let sum = 0

  useEffect(() => {
    api.readBuildings().then((res: any) => {
      setJsonlist(res.data)
      return res.data
    }).catch((err) => {
      console.log(err);
    });
  }, []);

  return (
    <Body paddingTop={3}>
      <div style={{ maxWidth: 700, width: "100%" }}>
        <Paper
          sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="例：縄文土器、名古屋大学 博物館"
            value={id}
            onChange={setId && ((e) => setId(e.target.value))}

          />
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>


        <Divider
          style={{
            marginTop: 13,
            height: 2,
            maxWidth: 700, 
            width: "100%",
            color: "#696969",

          }}
        ></Divider>
        {id === "" ?
          <Typography sx={{ marginTop: 1, fontColor: "#3C4043" }}>資料名、属性(土器、石器など)、所蔵館、所蔵者、関連場所、関連ワードから検索できます。
          </Typography> :
          <div />
        }

      </div>
      <div
        style={{
          textAlign: "center",
          marginTop: 6,
          position: "relative",
          zIndex: 10,

        }}
      >


        {jsonlist.map((todo: any, num_id: number) => {
          if (todo.museum_name.includes(id) || todo.name.includes(id) || todo.property_name.includes(id) || todo.excavator.includes(id) || todo.location.includes(id) || todo.commentary.includes(id)) {
            console.log("pass")
          } else {
            sum += 1
          }

          let path = settings.apiUrl + todo.image_path.slice(1)
          return (
            <div>
              {id === "" ?
                <></> :
                todo.museum_name.includes(id) || todo.name.includes(id) || todo.property_name.includes(id) || todo.excavator.includes(id) || todo.location.includes(id) || todo.commentary.includes(id) ?
                  <>
                    <Card sx={{ maxWidth: 700, width: "100%" }}>
                      <CardActionArea style={{ padding: 5 }}>
                        <Link underline="none" href={"/contents/" + todo.id}>
                          <div style={{ marginRight: 260 }}>
                            <Card sx={{ maxWidth: 90 }}>
                              <CardActionArea>
                                <Link underline="none" href={"/contents/" + todo.id}>
                                  <CardMedia
                                    component="img"
                                    alt="green iguana"
                                    height="75"
                                    image={path}
                                  />
                                  <div style={{ marginLeft: 10, textAlign: "left" }}>
                                  </div>
                                </Link>
                              </CardActionArea>
                            </Card>
                          </div>
                          <div style={{ color: "#1C1D1F" }}>
                            <div style={{ textAlign: "left", fontSize: 15, marginTop: -78, marginLeft: 100 }}>
                              <Typography textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" sx={{ marginTop: -0, marginLeft: 0, fontSize: 13 }}>資料名：{todo.name}</Typography>
                              <Typography textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" sx={{ fontSize: 13 }}>属性：{todo.property_name}</Typography>
                              <Typography textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" sx={{ fontSize: 13 }}>所蔵館：{todo.museum_name}</Typography>
                              <Typography textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" sx={{ fontSize: 13 }}>所蔵者：{todo.excavator}</Typography>
                            </div>
                            <div style={{ textAlign: "left", fontSize: 15, marginTop: 5, marginLeft: 0 }}>
                              <Typography textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" sx={{ fontSize: 13 }}>関連場所：{todo.location}</Typography>
                              <Typography textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" sx={{ fontSize: 13 }}>解説：{todo.commentary}</Typography>
                            </div>
                          </div>
                        </Link>
                      </CardActionArea>
                    </Card>

                    <Divider
                      style={{
                        marginTop: 5,
                        marginBottom: 6,
                        height: 2,
                        maxWidth: 700, 
                        width: "100%",
                        color: "#696969",

                      }}
                    ></Divider>

                  </> : sum === jsonlist.length ?
                    <div style={{ color: "#1C1D1F" }}>
                      <Typography style={{ textAlign: "left" }} >一致する検索結果はありませんでした。</Typography>
                    </div> :
                    <>
                    </>}
            </div>
          );
        })}

      </div>
    </Body>
  );
};
