import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { ChangeLanguage } from "./hamburgerIcon";
import { HeaderNavigation } from "./header_navigation";
import { settings } from "../../../settings";
import HomeIcon from '@mui/icons-material/Home';
import MuseumIcon from '@mui/icons-material/Museum';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import MediaQuery from "react-responsive";

type Props = {
  page_name: string;
  maxWidth?: number;
  value?: number;
  onChange?: (event: React.SyntheticEvent<Element, Event>, value: any) => void;
};

export const THeader: React.FC<{
  left?: JSX.Element | string | null;
  right?: JSX.Element | string | null;
}> = ({ left = null, right = null }) => {
  return (
    <AppBar
      sx={{
        backgroundColor: "#34A7B1",
        boxShadow: "none",
        paddingTop: "18px",
        height: "64px",
      }}
    >
      <Toolbar
        sx={{
          margin: "0 auto",
          padding: 0,
          paddingLeft: "5px",
          paddingRight: "5px",
          width: "100%",
          maxWidth: settings.maxWidth,
          display: "flex",
          justifyContent: "space-between",
          boxSizing: "border-box"
        }}
        variant="dense"
      >
        <div>{left}</div>
        <div>{right}</div>
      </Toolbar>
    </AppBar>
  );
};

const Header = ({ page_name, maxWidth = settings.maxWidth, value, onChange  }: Props) => {
  return (
    <AppBar
      sx={{ backgroundColor: "#fff" }}
      position="sticky"
      color="inherit"
    >
      <Toolbar
        variant="dense"
        sx={{
          margin: "0 auto",
          paddingTop: "15px",
          paddingLeft: "7px",
          paddingRight: "7px",
          paddingBottom: "5px",
          width: "100%",
          maxWidth: maxWidth,
          display: "flex",
          justifyContent: "space-between",
          boxSizing: "border-box"
        }}
        disableGutters
      >
        {page_name === "トップ画面" ? (
          <img
            style={{ textAlign: "center", marginTop: -7 }}
            src={`${process.env.PUBLIC_URL}/logo.png`}
            alt="Image"
            width="140px"
          />
        ) : page_name === "Rooting" ? (
          <div />
        ) : page_name === "資料検索" ? (
          <Typography
            style={{
              color: "#3C4043",
              fontWeight: "bold",
              fontSize: 21,
            }}
            variant="h6"
          >
            資料検索
          </Typography>
        ) : page_name === "管理画面" ? (
          <Typography
            style={{
              color: "#3C4043",
              fontWeight: "bold",
              fontSize: 21,
            }}
            variant="h6"
          >
            管理画面
          </Typography>
        ) : page_name === "Menu" ? (
          <Typography
            style={{
              color: "#fff",
              fontWeight: "bold",
              fontSize: 21,
            }}
            variant="h6"
          >
            Menu
          </Typography>
        ) : page_name === "博物館一覧" ? (
          <Typography
            style={{
              color: "#3C4043",
              fontWeight: "bold",
              fontSize: 21,
            }}
            variant="h6"
          >
            博物館一覧
          </Typography>
        ) : page_name === "建物内図" ? (
          <HeaderNavigation text="ホーム" href="/main/1" />
        ) : page_name === "戻る" && window.history.length >= 2 ? (
          <HeaderNavigation text="戻る" onClick={() => window.history.back()} />
        ) : page_name === "戻る" && window.history.length == 1 ? (
          <HeaderNavigation text="ホーム画面" href="/main/1" />
        ) : page_name === "ホーム" ? (
          <HeaderNavigation text="ホーム画面" href="/main/1" />
        ) : page_name === "BuildingInteriorMap" ? (
          <HeaderNavigation text="Buildings&Maps" href="/main_en/2" />
        ) : page_name === "建物内表示" ? (
          <HeaderNavigation
            text="経路案内"
            onClick={() => window.history.back()}
          />
        ) : page_name === "Directions" ? (
          <HeaderNavigation
            text="Directions"
            onClick={() => window.history.back()}
          />
        ) : page_name === "博物館一覧" ? (
          <HeaderNavigation text="博物館一覧" href="/main/2" />
        ) : page_name === "MenuLink" ? (
          <HeaderNavigation text="Menu" href="/main_en/0" />
        ) : page_name === "使い方リンク" ? (
          <HeaderNavigation text="戻る" href="/usage" />
        ) : page_name === "3Dデータ" ? (
          <HeaderNavigation text="3Dデータ一覧" href="/usage" />
        ) : (
          <Typography style={{ color: "#fff" }} variant="h6">
            <img
              style={{ textAlign: "center", marginBottom: -10 }}
              src={`${process.env.PUBLIC_URL}/images/logo_mark_with_smalltype.png`}
              alt="Image"
              width="30px"
            />
          </Typography>
        )}
        <ChangeLanguage />

      </Toolbar>
    </AppBar>
  );
};

export default Header;
