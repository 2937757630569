import Header from "../util/header"
import React from "react";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";

const style = {
  height: window.innerHeight,
  width: '100%',
  overflow: 'scroll',
}

const Outline: React.FC = () => {
  return (
    <div>
      <div style={{ zIndex: 19, position: "fixed", width: "100%" }}>
        <Header page_name={"戻る"} />
      </div>
      <div style={{ height: "20%", position: "relative" }}>
      <div style={style}>
      <div style={{ marginTop:100, maxWidth: 1200, width: "96%", marginLeft: "auto", marginRight: "auto" }} >

            <Typography
              style={{
                fontSize: 18,
                marginLeft: -3,
                marginTop:20,
                marginBottom: 15,
                color: "#3C4043",
                fontWeight: "bold",
              }}
            >
              アプリ概要
            </Typography>
            <Divider
              style={{
                marginTop: -13,
                height: 2,
                width: "100%",
                marginLeft: "auto",
                marginRight: "auto",
                color: "#696969",
              }}
            ></Divider>

            <Typography
              style={{
                fontSize: 16,
                marginLeft: 0,
                marginBottom: 5,
                marginTop: 10,
                color: "#3C4043",
                textAlign: "justify",
              }}
            >
              Cuplpticon（カルプティコン）は名古屋大学で開発中の文化財3Dデータ閲覧用Webアプリです。Culpticonを使うことで、全国各地の博物館・美術館が所有する文化財の3Dデータを横断的に鑑賞することができます。これまでも文化財3Dデータの閲覧アプリは数多く作られてきましたが、自治体や博物館施設が独自に作成することが多く、関連する作品や資料を横断的に見ていくことができませんでした。そこでCulpticonは、文化財3Dデータの統合された鑑賞用プラットフォームとして、自治体や博物館施設の垣根を越えて、気軽に作品を楽しめる環境の構築を目指します。さらに、あらゆる人々に開かれた、使いやすくて見やすい、そして思わず作品を共有したくなるようなサービスを展開し、文化財の新しい楽しみ方を提供していきます。
            </Typography>
            <Typography
              style={{
                fontSize: 16,
                marginLeft: 0,
                marginBottom: 5,
                marginTop: 15,
                color: "#3C4043",
                textAlign: "justify",
              }}
            >
              お問い合わせ先 名古屋大学考古学研究室（梶原義実）：kajiwara@nagoya-u.jp
            </Typography> 
            <div style={{ height: 60 }} />
          </div>
        </div>
    </div>
    </div>
  )
}
export default Outline;
