import { BottomNavigation as MuiBottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import MuseumIcon from '@mui/icons-material/Museum';
import SearchIcon from '@mui/icons-material/Search';

const navigationSx = { paddingBottom: 2, "&.Mui-selected": { color: "#52a9ff" } };

export const BottomNavigation = ({ value, onChange }: { value: number, onChange: (value: number) => void }) => {
  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 20 }} elevation={3}>
      <MuiBottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue: unknown) => {
          if (typeof newValue === "number") {
            onChange(newValue);
          }
        }}
        sx={{ height: 77 }}
      >
        <BottomNavigationAction sx={navigationSx} label="資料検索" icon={<SearchIcon />} />
        <BottomNavigationAction sx={navigationSx} label="ホーム" icon={<HomeIcon />} />
        <BottomNavigationAction sx={navigationSx} label="博物館一覧" icon={<MuseumIcon />} />
      </MuiBottomNavigation>
    </Paper>
  );
};
