import { ReactNode } from "react";
import { Center } from "./center";
import { settings } from "../../../settings";

export const Body = ({ children, paddingTop }: { children: ReactNode, paddingTop?: number }) => {
    return (
        <Center
            maxWidth={settings.maxWidth} minHeight="100vh" xs={12} boxSizing="border-box"
            paddingTop={paddingTop ?? 5} paddingBottom={10} paddingLeft={1} paddingRight={1}
        >
            {children}
        </Center>
    );
};
