import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { useQuery, QueryClient, useMutation, UseQueryOptions } from "@tanstack/react-query";
import { settings } from "../settings";

export class CulpticonApi {
    public readonly baseURL: string = settings.apiUrl;
    public readonly queryClient: QueryClient;

    public readonly get: <T = any, R = AxiosResponse<T, any>, D = any>(url: string, config?: AxiosRequestConfig<D> | undefined) => Promise<R>
    public readonly post: <T = any, R = AxiosResponse<T, any>, D = any>(url: string, data?: D | undefined, config?: AxiosRequestConfig<D> | undefined) => Promise<R>
    public readonly patch: <T = any, R = AxiosResponse<T, any>, D = any>(url: string, data?: D | undefined, config?: AxiosRequestConfig<D> | undefined) => Promise<R>
    public readonly delete: <T = any, R = AxiosResponse<T, any>, D = any>(url: string, config?: AxiosRequestConfig<D> | undefined) => Promise<R>

    constructor() {
        this.queryClient = new QueryClient();
        const api = axios.create({
            baseURL: this.baseURL,
        });
        this.get = api.get;
        this.post = api.post;
        this.patch = api.patch;
        this.delete = api.delete;
    }

    public readonly readBuildings = async () => {
        const res: AxiosResponse<unknown> = await this.get('/archaeological_data_all');
        console.log(res)
        return res
        //return z.array(Building).parse(res.data);
    };

    public readonly updateArchaeologicalData = async (id: string, data: { [key: string]: any }) => {
        const res: AxiosResponse<unknown> = await this.patch(`/archaeological_data/${encodeURIComponent(id)}`, data);
        console.log(res)
        return res
    };

    public readonly readBuildingId = async (id: string) => {
        const res: AxiosResponse<unknown> = await this.get(`/archaeological_data_id/`+encodeURIComponent(id));
        console.log(res.data)
        return res.data
    };

    public readonly deleteBuildingId = async (id: string) => {
        const res: AxiosResponse<unknown> = await this.get(`/archaeological_data_delete_id/`+encodeURIComponent(id));
        console.log(res)
        return res
        //return z.array(Building).parse(res.data);
    };

    public readonly createRoom = async (name: string, commentary: string, excavator: string, location: string, data: File) => {
        const res: AxiosResponse<unknown> = await this.post('/images/'+encodeURIComponent(name)+"/"+encodeURIComponent(commentary)+"/"+encodeURIComponent(excavator)+"/"+encodeURIComponent(location), data);
        console.log(res)
        return res
    };

    public readonly updateImageFile = async (id: string, file: File): Promise<AxiosResponse> => {
        const formData = new FormData();
        formData.append('image', file);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        const response = await axios.patch(`${this.baseURL}/archaeological_data/${encodeURIComponent(id)}/image`, formData, config);
        console.log(response);
        return response;
    };

    public readonly updateObjFile = async (id: string, file: File) => {
        const formData = new FormData();
        formData.append('obj_file', file);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        const res: AxiosResponse<unknown> = await this.patch(`/archaeological_data/${encodeURIComponent(id)}/obj_file`, formData, config);
        console.log(res);
        return res;
    };
    public readonly updateObjNontextureFile = async (id: string, file: File): Promise<AxiosResponse> => {
        const formData = new FormData();
        formData.append('obj_nontexture_file', file);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        const response = await axios.patch(`${this.baseURL}/archaeological_data/${encodeURIComponent(id)}/obj_nontexture_file`, formData, config);
        console.log(response);
        return response;
    };
}
