import Header from "../util/header"
import React from "react";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";

const style = {
  height: window.innerHeight,
  width: '100%',
  overflow: 'scroll',
}

const Credit: React.FC = () => {
  return (
    <div>

      <div style={{ zIndex: 19, position: "fixed", width: "100%" }}>
        <Header page_name={"戻る"} />
      </div>
      <div style={{ height: "20%", position: "relative" }}>
        <div style={style}>
        <div style={{ marginTop:100, maxWidth: 1200, width: "96%", marginLeft: "auto", marginRight: "auto" }} >

            <Typography
              style={{
                fontSize: 21,
                marginTop:20,
                marginBottom: 15,
                color: "#3C4043",
                fontWeight: "bold",
              }}
            >
              利用規約
            </Typography>
            <Divider
              style={{
                marginTop: -13,
                height: 2,
                width: "100%",
                marginLeft: "auto",
                marginRight: "auto",
                color: "#696969",
              }}
            ></Divider>

            <Typography
              style={{
                fontSize: 16,
                marginBottom: 5,
                marginTop: 10,
                color: "#3C4043",
                textAlign: "justify",
              }}
            >
              本規約は，利用者による利用の一切について適用されるものとします。本サービスを利用するには本規約に同意していただくことが条件となりますので，ご利用前に必ず本規約をご覧いただき，ご理解いただくようお願いいたします。
            </Typography>
            <Typography
              style={{
                fontSize: 18,
                marginTop: 20,
                color: "#3C4043",
                fontWeight: "bold",
              }}
            >
              本規約の適用範囲等
            </Typography>

            <Typography
              style={{
                fontSize: 16,
                marginBottom: 5,
                marginTop: 10,
                color: "#3C4043",
                textAlign: "justify",
              }}
            >
              1.利用者は，本サービスをご利用される前に本規約に同意していただく必要があります。同意いただけない場合，本サービスを利用することはできません
            </Typography>
            <Typography
              style={{
                fontSize: 16,
                marginBottom: 5,
                marginTop: 10,
                color: "#3C4043",
                textAlign: "justify",
              }}
            >
              2.利用者の了承を得ることなく本規約を変更する場合がございます。変更後の規約は，それが本サービスの画面に表示された時点で効力を発揮するものとします。本規約の変更後に本サービスを利用された場合は，変更後の規約に同意されたものとみなします。
            </Typography>
            <Typography
              style={{
                fontSize: 18,
                marginTop: 20,
                color: "#3C4043",
                fontWeight: "bold",
                }}
            >
              サービス内容等
            </Typography>
            <Typography
              style={{
                fontSize: 16,
                marginBottom: 5,
                marginTop: 10,
                color: "#3C4043",
                textAlign: "justify",
              }}
            >
              1.本サービスは無料で提供しておりますが，本サービスを利用する際に発生する通信料については利用者負担となります。
            </Typography>
            <Typography
              style={{
                fontSize: 16,
                marginBottom: 5,
                marginTop: 10,
                color: "#3C4043",
                textAlign: "justify",
              }}
            >
              2.本サービスは実証実験の一環として構築されたものであり，予告なく運用の中断や内容の変更等を行うことがあります。
            </Typography>
            <Typography
              style={{
                fontSize: 16,
                marginBottom: 5,
                marginTop: 10,
                color: "#3C4043",
                textAlign: "justify",
              }}
            >
              3.本サービスはGoogle Chrome，Apple Safari，Microsoft Edgeで動作確認をしております。Internet Explorerでも確認しておりますが，正しく表示されない可能性がありますのでご了承ください。
            </Typography>
            <Typography
              style={{
                fontSize: 18,
                marginTop: 20,
                color: "#3C4043",
                fontWeight: "bold",
              }}
            >
              免責事項
            </Typography>
            <Typography
              style={{
                fontSize: 16,
                marginBottom: 5,
                marginTop: 10,
                color: "#3C4043",
                textAlign: "justify",
              }}
            >
              利用者は，利用者自身の責任において本サービスを利用するものとします。したがって，本サービスを利用してなされた一連の行為やその結果生じた損害について，利用者が一切の責任を負うことに同意するものとします。
            </Typography>
            <Typography
              style={{
                fontSize: 16,
                marginBottom: 5,
                marginTop: 10,
                color: "#3C4043",
                textAlign: "justify",
              }}
            >
              1.本サービスは実証実験として構築されたものであり，可用性，正確性，妥当性，有用性，特定の目的に対する適合性等について，一切の保障を致しません。
            </Typography>
            <Typography
              style={{
                fontSize: 16,
                marginBottom: 5,
                marginTop: 10,
                color: "#3C4043",
                textAlign: "justify",
              }}
            >
              2.本サービスによって得た情報に基づいた利用者の判断により発生した一切の損害について，いかなる責任も負いません。ご了承ください。
            </Typography>
            <Typography
              style={{
                fontSize: 16,
                marginBottom: 5,
                marginTop: 10,
                color: "#3C4043",
                textAlign: "justify",
                
              }}
            >
              3.本サービスの変更や内容の欠陥等によって生じたあらゆる損害について理由の如何に関わらずいかなる責任も負いません。ご了承ください。
            </Typography>
            <Typography
              style={{
                fontSize: 18,
                marginTop: 20,
                color: "#3C4043",
                fontWeight: "bold",
              }}
            >
              禁止事項
            </Typography>
            <Typography
              style={{
                fontSize: 16,
                marginBottom: 5,
                marginTop: 10,
                color: "#3C4043",
                textAlign: "justify",
              }}
            >
              利用者は，次に掲げる行為を行ってはならないものとします。利用者の違反行為により生じた損害は，その全てに関して賠償責任を負うものとします。
            </Typography>
            <Typography
              style={{
                fontSize: 16,
                marginBottom: 5,
                marginTop: 10,
                color: "#3C4043",
                textAlign: "justify",
              }}
            >
              1.本サービスを構築するサーバー，ネットワークへ過負荷をかけうる行為や，何らかの支障を及ぼしうる行為
            </Typography>
            <Typography
              style={{
                fontSize: 16,
                textAlign: "justify",
                marginBottom: 5,
                marginTop: 10,
                color: "#3C4043",
              }}
            >
              2.本サービスで使用されているAPIを本サービス以外で利用する行為
            </Typography>
            <Typography
              style={{
                fontSize: 16,
                textAlign: "justify",
                marginBottom: 5,
                marginTop: 10,
                color: "#3C4043",
              }}
            >
              3.公序良俗または本規約に違反する行為
            </Typography>
            <Typography
              style={{
                fontSize: 18,
                marginTop: 20,
                color: "#3C4043",
                fontWeight: "bold",
              }}
            >
              ご利用環境について
            </Typography>

            <Typography
              style={{
                fontSize: 16,
                textAlign: "justify",
                marginBottom: 5,
                marginTop: 10,
                color: "#3C4043",
              }}
            >
              本サービスはスマートフォンならびにPC，タブレット端末上のウェブブラウザでご利用になれます。その他，特定のアプリは不要です。ただし，Internet Explorerでは正しく表示されない可能性がありますのでご了承ください。
            </Typography>
            <div style={{ height: 60 }} />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Credit;
