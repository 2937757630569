import Header from "../util/header";
import React, { useEffect } from "react";
import { Divider, Typography, Grid } from '@mui/material';
import { useParams } from "react-router";
import { CulpticonApi } from "../../../utils/api";
import { Body } from "../layout";
import { ItemCard } from "../util";
import { settings } from "../../../settings";


const api = new CulpticonApi();

const UsageEn = () => {
  const [jsonlist, setJsonlist] = React.useState<any>([]);
  const { id } = useParams<"museum" | "id">();

  useEffect(() => {
    api.readBuildings().then((res: any) => {
      setJsonlist(res.data)
      return res.data
    }).catch((err) => {
      console.log(err);
    });
  }, []);

  return (
    <>
      <Header page_name="戻る" />
      <Body>
        <Typography sx={{ fontSize: 21 }} fontWeight="bold" marginTop={-2} >
          {id}
        </Typography>
        <Divider />
        <Grid container spacing={1} marginTop={1}>
          {jsonlist.filter((item: any) => item.museum_name === id).map((item: any, index: number) => {
            const path = settings.apiUrl + item.image_path.slice(1);
            return (
              <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                <ItemCard img={path} name={item.name} text={item.commentary} to={`/contents/${item.id}`} />
              </Grid>
            );
          })}
        </Grid>
      </Body>
    </>
  );
};

export default UsageEn;
