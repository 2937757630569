import { useState } from "react";
import {
  TextField, Button, Typography, AppBar, Toolbar, Divider, FormControlLabel, LinearProgress,
  FormControl, RadioGroup, Radio
} from "@mui/material";
import axios, { AxiosError } from "axios"
import { DropZone } from "../component/util";
import { Stack } from "@mui/material";
import { settings } from "../../settings";
import { Property } from "../../types";

const Admin = () => {
  const [museum_name, setMuseumName] = useState("");
  const [name, setName] = useState("");
  const [property, setProperty] = useState<Property>("その他");
  const [commentary, setCommentary] = useState("");
  const [excavator, setExcavator] = useState("");
  const [location, setLocation] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState<File | null>(null)
  const [objFile, setObjFile] = useState<File | null>(null)
  const [objNontextureFile, setObjNontextureFile] = useState<File | null>(null)

  let mapSrc = "https://maps.google.com/maps?output=embed&q=" + location

  const handleUpload = async () => {
    if (!imageFile || !objFile || !objNontextureFile) {
      throw new Error("file not set");
    }
    const header = {
      headers: {
        "Access-Control-Allow-Origin": "*"
      }
    };
    const data = new FormData()
    setLoading(true)
    data.append('image', imageFile)
    data.append('obj_file', objFile)
    data.append('obj_nontexture_file', objNontextureFile)

    try {
      const response = await axios.post(settings.apiUrl + '/archaeological_data', {
        "museum_name": museum_name,
        "name": name,
        "property": property,
        "commentary": commentary,
        "excavator": excavator,
        "location": location
      }, header)
      const id: string = response.data.id
      await axios.post(settings.apiUrl + `/archaeological_data/${id}/files`, data, header)
      alert("アップロードに成功しました")
      window.location.href = "/admin";
    } catch (error) {
      setLoading(false)
      alert("アップロードに失敗しました")
    }
  };

  return (
    <>
      <div style={{ zIndex: 19, position: "fixed", width: "100%" }}>
        <AppBar
          sx={{
            backgroundColor: "#fff",
            paddingTop: "18px",
            height: "64px",
          }}
        >
          <Toolbar
            sx={{
              margin: "0 auto",
              padding: 0,
              paddingLeft: "5px",
              paddingRight: "5px",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
            variant="dense"
            disableGutters
          >
            <Typography sx={{ color: "#2b2b2b", fontSize: 25, fontWeight: "bold", marginLeft: 1 }}>管理画面</Typography>
          </Toolbar>
        </AppBar>
      </div>
      <div style={{ height: 100 }} />

      <div style={{ width: 600, marginLeft: "auto", marginRight: "auto" }}>
        <Typography sx={{ fontSize: 22, fontWeight: "bold", color: "#2b2b2b" }}>考古資料の登録</Typography>
        <Button onClick={() => window.location.href = "/delete"} sx={{ marginTop: -8, marginLeft: 56 }} variant="outlined" color="primary">登録の確認と削除</Button>
        <Divider sx={{ width: "100%", marginBottom: 1, marginTop: -1.5 }} />
        <Typography sx={{ color: "#5f5f5f", marginTop: 1 }}>所蔵館/組織名</Typography>
        <div><TextField sx={{ width: "100%" }} value={museum_name} onChange={setMuseumName && ((e) => setMuseumName(e.target.value))} id="outlined-basic" size="small" /></div>
        <Typography sx={{ color: "#5f5f5f", marginTop: 1 }}>名前</Typography>
        <div><TextField sx={{ width: "100%" }} value={name} onChange={setName && ((e) => setName(e.target.value))} id="outlined-basic" size="small" /></div>
        <Typography sx={{ color: "#5f5f5f", marginTop: 1, marginBottom: -1 }}>属性</Typography>
        <FormControl>
          <RadioGroup
            value={property}
            onChange={(e) => {
              const property = Property.parse(e.target.value);
              setProperty(property);
            }}
            row
          >
            <FormControlLabel value="土器" control={<Radio />} label="土器" />
            <FormControlLabel value="石器" control={<Radio />} label="石器" />
            <FormControlLabel value="骨角器" control={<Radio />} label="骨角器" />
            <FormControlLabel value="その他" control={<Radio />} label="その他" />
          </RadioGroup>
        </FormControl>
        <Typography sx={{ color: "#5f5f5f" }}>解説</Typography>
        <TextField
          sx={{ width: "100%", marginBottom: 1 }}
          id="outlined-multiline-static"
          value={commentary}
          multiline
          rows={6}
          onChange={setCommentary && ((e) => setCommentary(e.target.value))}
        />
        <Typography sx={{ color: "#5f5f5f" }}>所蔵者</Typography>
        <div><TextField sx={{ width: "100%", marginBottom: 1 }} value={excavator} onChange={setExcavator && ((e) => setExcavator(e.target.value))} id="outlined-basic" size="small" /></div>
        <Typography sx={{ color: "#5f5f5f" }}>関連地図</Typography>
        <div><TextField sx={{ width: "100%", marginBottom: 1 }} value={location} onChange={setLocation && ((e) => setLocation(e.target.value))} id="outlined-basic" size="small" /></div>
        <iframe title="googleMaps" src={mapSrc} width="100%" height="300" frameBorder="0" />
        <Stack spacing={2} marginTop={2} marginBottom={2}>
          <DropZone
            text="画像ファイルをドラッグ&amp;ドロップ"
            subText={imageFile?.name}
            options={{
              onDrop: (files: File[]) => {
                const file = files[0];
                console.log(file);
                if (file) {
                  console.log(file);
                  setImageFile(file);
                }
              },
              accept: {
                'image/*': []
              }
            }}
          />
          <DropZone
            text="GLBファイルをドラッグ&amp;ドロップ"
            subText={objFile?.name}
            options={{
              onDrop: (files: File[]) => {
                const file = files[0];
                if (file) {
                  setObjFile(file);
                }
              },
              accept: {
                '.glb': []
              }
            }}
          />
          <DropZone
            text="GLBファイル(テクスチャ無し)をドラッグ&amp;ドロップ"
            subText={objNontextureFile?.name}
            options={{
              onDrop: (files: File[]) => {
                const file = files[0];
                if (file) {
                  setObjNontextureFile(file);
                }
              },
              accept: {
                '.glb': []
              }
            }}
          />
        </Stack>
        {loading === false ? <Button onClick={handleUpload} style={{ marginTop: 20, marginBottom: 40 }} variant="contained" size="large">アップロード</Button> :
          <LinearProgress style={{ marginTop: 20, marginBottom: 40 }} />}

      </div>
    </>
  );
};

export default Admin;
