import { Suspense, useMemo } from "react";
import { Canvas } from "@react-three/fiber";
import { useGLTF, Stage, OrbitControls } from "@react-three/drei";
import { CircularProgress, Typography } from "@mui/material";
import { ErrorBoundary } from 'react-error-boundary'
import { Center } from "../layout";
import { settings } from "../../../settings";

const Model = ({ glbSrc, texture }: { glbSrc: string, texture: boolean }) => {
  const path = texture ? settings.apiUrl + "/static/" + glbSrc + "/obj_file/obj.glb" : settings.apiUrl + "/static/" + glbSrc + "/obj_nontexture_file/obj.glb";
  const { scene } = useGLTF(path);
  return (
    <primitive object={scene} scale={[10, 10, 10]} />
  );
};

export const ThreeJs = ({ glbSrc, texture, height = 300 }: { glbSrc: string, texture: boolean, height?: number | string }) => {
  const style = useMemo(() => ({
    width: "100%", height: height, backgroundColor: "black",
  }), [height]);

  return (
    <div style={style}>
      <ErrorBoundary
        fallbackRender={() => (
          <Center containerStyle={{ display: "flex", alignItems: "center", height: "100%", textAlign: "center" }}>
            <Typography sx={{ color: "white" }}>読み込みに失敗しました。</Typography>
          </Center>
        )}
      >
        <Suspense
          fallback={
            <Center containerStyle={{ display: "flex", alignItems: "center", height: "100%", textAlign: "center" }}>
              <CircularProgress size={100} sx={{ color: "white" }} />
              <Typography sx={{ color: "white" }}>3Dデータを読み込み中です。</Typography>
              <Typography sx={{ color: "white" }}>10秒ほどかかる場合もあります。</Typography>
            </Center>
          }
        >
          <Canvas>
            <ambientLight />
            <OrbitControls enablePan={true} enableZoom={true} enableRotate={true} />
            <Stage>
              <Model glbSrc={glbSrc} texture={texture} />
            </Stage>
          </Canvas>
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};
