import Header from "../util/header";
import React, { useEffect } from "react";
import { Typography, Card } from "@mui/material";
import { useParams } from "react-router";
import Divider from "@mui/material/Divider";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { ThreeJs } from "./threeJs";
import { DialogContent } from "@mui/material";
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, LineIcon, LineShareButton } from 'react-share';
import Button from '@mui/material/Button';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { CulpticonApi } from "../../../utils/api";
import { QRCodeCanvas } from "qrcode.react";
import { settings } from "../../../settings";

const api = new CulpticonApi()

const Contents: React.FC = () => {
  const [noTexture, setNoTexture] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openQr, setOpenQr] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    window.location.href = settings.origin + '/contents/' + id;
  }

  const handleOpenQr = () => setOpenQr(true);
  const handleCloseQr = () => {
    setOpenQr(false);
  }

  const { id } = useParams();
  const [jsonlist, setJsonlist] = React.useState<any>([]);

  const qrcode = (
    <QRCodeCanvas
      id="qrCode"
      value={settings.origin + '/contents/' + id}
      size={355}
      level={"H"}
    />
  );

  useEffect(() => {
    api.readBuildingId(id as string).then((res: any) => {
      setJsonlist(res)
      return res.data
    }).catch((err) => {
      console.log(err);
    });
  }, []);

  const toggleNoTexture = () => {
    setNoTexture(state => !state);
  };

  const style = {
    height: window.innerHeight,
    width: "100%",
    overflow: "scroll",
  };

  let mapSrc = "https://maps.google.com/maps?output=embed&q=" + jsonlist.location

  return (
    <>
      <div style={{ zIndex: 19, position: "fixed", width: "100%" }}>
        <Header page_name={"戻る"} />
      </div>

      <div style={{ position: "relative" }}>
        <div style={style}>
          <div style={{ height: 87 }} />
          <div style={{ maxWidth: 1200, width: "96%", marginLeft: "auto", marginRight: "auto" }}>
            <Button sx={{ marginBottom: 2 }} size="small" color="inherit" onClick={handleOpenQr} variant="outlined" startIcon={<QrCode2Icon />}>
              QRコード共有
            </Button>
            <Typography
              style={{
                fontSize: 22,
                marginRight: "auto",
                marginBottom: 15,
                color: "#000",
                fontWeight: "bold",
              }}
            >
              {jsonlist.name}

            </Typography>
            <Divider
              style={{
                marginTop: -14,
                height: 2,
                width: "100%",
                marginLeft: "auto",
                marginRight: "auto",
                color: "#696969",
              }}
            ></Divider>
            <div style={{ marginTop: 12 }}>
              {id && <ThreeJs glbSrc={id} texture={!noTexture} height={window.innerWidth < 700 ? 350 : 500} />}
              {/*<IconButton sx={{ marginLeft: -1, marginTop: -3 }} onClick={handleOpen} aria-label="upload picture" component="span">
                  <OpenWithIcon sx={{ fontSize: 34 }} />
            </IconButton>*/}
              <TwitterShareButton url={window.location.href} title='「Culpticon」で3Dの文化財を楽しもう!' style={{ marginLeft: 5, marginTop: 13 }}>
                <TwitterIcon size={34} round />
              </TwitterShareButton>
              <FacebookShareButton url={window.location.href} quote='「Culpticon」で3Dの文化財を楽しもう!' style={{ margin: 4 }}>
                <FacebookIcon size={34} round />
              </FacebookShareButton>
              <LineShareButton url={window.location.href} title='「Culpticon」で3Dの文化財を楽しもう!' style={{ margin: 0 }}>
                <LineIcon size={34} round />
              </LineShareButton>

              <FormControlLabel
                style={{ marginLeft: 10, marginTop: -23 }}
                control={
                  <Switch checked={noTexture} onChange={toggleNoTexture} name="jason" />
                }
                label="テクスチャなし"
              />
            </div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Card style={{ marginTop: 100, maxWidth: 1200, width: "95%", height: window.innerWidth < 700 ? 450 : 600, marginLeft: "auto", marginRight: "auto", backgroundColor: "#000" }}>
                <div style={{ width: "100%", textAlign: "right" }}>
                  <IconButton sx={{ marginTop: 0.5, }} onClick={handleClose} aria-label="upload picture" component="span">
                    <ClearIcon sx={{ fontSize: 34, color: "#fff" }} />
                  </IconButton>
                </div>
                <DialogContent>
                </DialogContent>
                {id && <ThreeJs glbSrc={id} texture={!noTexture} height={400} />}
              </Card>
            </Modal>
            <div style={{ width: "100%", marginLeft: "auto", marginRight: "auto", }}>
              <div>
                <Modal
                  open={openQr}
                  onClose={handleCloseQr}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <div style={{ marginTop: 75, width: 355, height: 400, marginLeft: "auto", marginRight: "auto" }}>
                    <IconButton sx={{ marginRight: -20, marginTop: 0.5, marginLeft: 39 }} onClick={handleClose} aria-label="upload picture" component="span">
                      <ClearIcon sx={{ fontSize: 34, color: "#fff" }} />
                    </IconButton>
                    <div style={{ marginTop: 0 }}>{qrcode}</div>
                  </div>
                </Modal>
              </div>
              <Typography
                style={{
                  fontSize: 21,
                  marginRight: "auto",
                  marginTop: 15,
                  marginBottom: 15,
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                解説
              </Typography>

              <Divider
                style={{
                  marginTop: -13,
                  height: 2,
                  width: "100%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  color: "#696969",
                }}
              ></Divider>
            </div>
            <div style={{ width: "100%", marginLeft: "auto", marginRight: "auto", marginTop: 10, }}>
              <Typography
                display="inline"
                style={{
                  fontSize: 18,
                  marginTop: 20,
                  marginBottom: 0,
                  color: "#000",
                }}
              >
                {jsonlist.commentary}
              </Typography>
            </div>
            <div style={{ width: "100%", marginLeft: "auto", marginRight: "auto", }}>
              <Typography
                style={{
                  fontSize: 21,
                  marginRight: "auto",
                  marginTop: 15,
                  marginBottom: 15,
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                所蔵者
              </Typography>

              <Divider
                style={{
                  marginTop: -13,
                  height: 2,
                  width: "100%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  color: "#696969",
                }}
              ></Divider>
            </div>
            <div style={{ width: "100%", marginLeft: "auto", marginRight: "auto", marginTop: 10, }}>
              <Typography
                display="inline"
                style={{
                  fontSize: 18,
                  marginTop: 20,
                  marginBottom: 0,
                  color: "#000",
                }}
              >
                {jsonlist.excavator}
              </Typography>
            </div>
            <div>
              <div style={{ width: "100%", marginLeft: "auto", marginRight: "auto", }}>
                <Typography
                  style={{
                    fontSize: 21,
                    marginRight: "auto",
                    marginTop: 15,
                    marginBottom: 15,
                    color: "#000",
                    fontWeight: "bold",
                  }}
                >
                  発掘場所
                </Typography>

                <Divider
                  style={{
                    marginTop: -13,
                    height: 2,
                    width: "100%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    color: "#696969",
                  }}
                ></Divider>
              </div>
              <div style={{ width: "100%", marginLeft: "auto", marginRight: "auto", marginTop: 13, }}>
                <iframe src={mapSrc} width="100%" height="300" frameBorder="0" ></iframe>
              </div>
            </div>

            <div style={{ height: 50 }} />
          </div>
        </div>
      </div>

    </>
  );
};

export default Contents;
