import Header from "../../util/header";
import React from "react";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";

const style = {
  height: window.innerHeight,
  width: "100%",
  overflow: "scroll",
};

const Genre: React.FC = () => {
  return (
    <>
      <Header page_name={"データ検索"} />
      <div style={{ height: 0, position: "relative" }}>
        <div style={style}>
          <div style={{ height: 20 }} />
          <div style={{ background: "#454064" }}></div>
          <div style={{ height: 0 }}></div>
          <div style={{ width: 350, marginLeft: "auto", marginRight: "auto", }}>
            <Typography
              style={{
                fontSize: 21,
                marginBottom: 15,
                color: "#3C4043",
                fontWeight: "bold",
              }}
            >
              ジャンルから探す
            </Typography>

            <Divider
              style={{
                marginTop: -13,
                height: 2,
                width: 350,
                marginLeft: "auto",
                marginRight: "auto",
                color: "#696969",
              }}
            ></Divider>
          </div>
        </div>
      </div>
    </>
  );
};

export default Genre;
